@import '../UiCompo/lets';
@import '../UiCompo/mixin-query';

.section {
  position: relative;

  .inner {
    max-width: 980px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    @include mobile {
      width: 100%;
    }
  }
}

// @include tablet {
//   .flex_container {
//     display: flex;
//     flex-direction: column;
//   }

//   .flex_content {
//     display: flex;
//     height: 350px;
//   }
// }

.summary {
  &__title {
    font-size: 38px;
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: 18px;
    text-align: center;
  }
  &__description {
    font-size: 26px;
    font-weight: 300;
    color: #767676;
    line-height: 1.5;
    text-align: center;
  }
}

.clearfix {
  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.float {
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

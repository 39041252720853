.playModal {
  li {
    position: relative;
    animation: mymove 5s infinite;
  }
}

// @keyframes mymove {
//   0% {
//     left: 0px;
//   }
//   20% {
//     left: 85px;
//   }
//   50% {
//     left: 0px;
//   }
//   75% {
//     left: -85px;
//   }
//   100% {
//     left: 0px;
//   }
// }

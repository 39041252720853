@import './UiCompo/lets';
@import './UiCompo/mixin-query';
/* common */
.body__container {
  background: floralwhite;

  font-family: 'Lovers Quarrel', cursive;
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

a {
  text-decoration: none;
}

// @include xmobile {
//   canvas {
//     display: block;
//     width: 100%;
//     height: 350px;
//   }
// }

@include tablet{
  .flex_contents {
    display: none;
  }
}


@include tablet {
  /*
  .flex_contents {
   // display: none;
   position: relative;
   font-weight: 600;
  }
  */
  .playmodalContent {
    display: none;
  }
}


@include xtablet {
  .playmodal {
    display: none;
  }
}

@include xtablet {
  .flex_content {
    display: none;
    height: 350px;
  }

  .flex_contents {
    // display: flex;
    height: 1119px;
    position: relative;
    font-weight: 600;
  }
}

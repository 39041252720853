@import '../UiCompo/lets';
@import '../UiCompo/mixin-query';

// span{
//   position: relative;
//   display: inline;
// }

.section--visual {
  // background-image: url("../pub1/20200727_164852-1.jpg");
  // background-repeat: no-repeat;
  // background-position: bottom top;
  // background-size: cover;
  background-color: slategrey;
 

  canvas {
    width: 124px;
    height: 68px;
  }

  .hvr-icon-down {
    cursor: pointer;
    position: absolute;
    left: 30%;
    top: 30%;
    color: black;
  }
  
  .hide {
    font-family: 'Do Hyeon', sans-serif;
    font-size: 18px;
    display: none;
  
    position: relative;

      // @include tabtop;
      // ul {
      //   position: absolute;
      //   li {
      //     display: none;
      //     img {
      //       width: 45px;
      //       height: 45px;
      //     }
      //   }
      // }

    @include desktop;
      ul {
        position: absolute;
        li {
          display: none;
          img {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  
  
  &:hover {
    /* Icon Down */
    @-webkit-keyframes hvr-icon-down {
      0%,
      50%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      25%,
      75% {
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
      }
    }
    @keyframes hvr-icon-down {
      0%,
      50%,
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      25%,
      75% {
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
      }
    }
    /* Icon Down */
    .hvr-icon-down {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0);
      cursor: pointer;
    }
    .hvr-icon-down .hvr-icon {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    .hvr-icon-down:hover .hvr-icon,
    .hvr-icon-down:focus .hvr-icon,
    .hvr-icon-down:active .hvr-icon {
      -webkit-animation-name: hvr-icon-down;
      animation-name: hvr-icon-down;
      -webkit-animation-duration: 0.75s;
      animation-duration: 0.75s;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  

  .hide {
    display: block;
    //background-color: yellow;
    height: 100%;
    // text-align: center;
    z-index: 1;

    @include desktop {
      ul {
        display: flex;
        list-style: none;
        align-items: left;
        position: absolute;
        top: 30%;
        li {
          display: block;
        }
      }
    }
    ol {
      list-style: none;
      li {
        display: block;
      }
    }
  }

  height: 60px;

 }
}


pre {
  padding : 4px;
  margin : 4px;
  line-height: 2px;
}
@import './lets';

@mixin sgmobile {
  //410px이하
  @media (max-width: #{$breakpoint-smobile - 1px}) {
    @content;
  }
}

@mixin mobile {
  //600px이하
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin smoblet {
  //410~~600
  @media (min-width: #{$breakpoint-smobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tabtop {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin xmobile {
  @media (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin xtablet {
  @media (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}
